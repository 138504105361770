import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取围栏列表
export const GetFenceList = (params = {}) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/ElectronicFence/GetFenceList`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

//新增围栏
export const AddFence = (params = {}) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/ElectronicFence/AddFence`, params).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

//删除围栏
export const DelFence = (params = {}) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/ElectronicFence/DelFence?FenceIds=${params}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}

//编辑围栏
export const EditFence = (params = {}) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/ElectronicFence/EditFence`, params).then(res => {
          if (res.code == 0) {
            resolve(res.data)
          } else {
            reject(res)
          }
      })
  })
}

//编辑围栏
export const GetFenceDeatil = (params = {}) => {
  return new Promise((resolve, reject) => {
      axios.post(`${host}/api/ElectronicFence/GetFenceDeatils?FenceId=${params}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}